<template>
  <div class="partial-discharge">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>特高频局放</span>
      </div>
      <div class="chart-content">
        <div class="chart">
          <div class="">
            <div
              class=""
              id="dischargeChart"
              style="width: 100%; height: 300px"
            ></div>
          </div>
          <div class="">
            <div
              class=""
              id="dBmChart"
              style="width: 100%; height: 300px"
            ></div>
          </div>
        </div>
      </div>
      <div>
        <el-table  class="table"
        :data="tableData"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <!-- <el-table-column align="center" label="序号" type="index" width="100">
            </el-table-column> -->
          <el-table-column align="center" prop="ultrasonicPeakName" label="名称" min-width="200">
          </el-table-column>
          <el-table-column align="center" prop="ultrasonicPeakValue" label="值" width="100">
          </el-table-column>
          <el-table-column align="center" prop="name" label="单位" width="100">
            <template slot-scope=""> dBm </template>
          </el-table-column>
          <el-table-column align="center" prop="ultrasonicMeanName" label="名称"min-width="200" >
          </el-table-column>
          <el-table-column align="center" prop="ultrasonicMeanValue" label="值" width="100">
          </el-table-column>
          <el-table-column align="center" prop="name" label="单位" width="100">
            <template slot-scope=""> dBm </template>
          </el-table-column>
          <el-table-column align="center" prop="earthWavePeakName" label="名称" min-width="200">
          </el-table-column>
          <el-table-column align="center" prop="earthWavePeakValue" label="值" width="100">
          </el-table-column>
          <el-table-column align="center" prop="name" label="单位" width="100">
            <template slot-scope=""> dBm </template>
          </el-table-column>
          <el-table-column align="center" prop="earthWaveMeanName" label="名称" min-width="200">
          </el-table-column>
          <el-table-column align="center" prop="earthWaveMeanValue" label="值" width="100">
          </el-table-column>
          <el-table-column align="center" prop="name" label="单位" width="100">
            <template slot-scope=""> dBm </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination
          :current-page="requestParam.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          background
          @size-change="changeSize"
          @current-change="changeCurrent"
        >
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input
            v-model.number="inputValue"
            size="mini"
            class="pagination-input"
            @input="checkPageNum"
            @change="inputValueChange"
          ></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { securityControlApi } from "@/api/securityControl.js";
import * as echarts from "echarts";
export default {
  name: "staffAddressBook",
  data() {
    return {
      chartParam: {
        id: "",
        endTime: "",
        staTime: "",
      },
      requestParam: {
        pageNum: 1,
        pageSize: 1,
        stationId: "",
      },
      loading: false,
      tableData: [],
      total: 0,
      inputValue: "",

      dataLoading: false,

      checkedNodes: [],
    };
  },
  created() {
    this.$store.commit("increment", "安全用电");
    this.$store.commit("selectChild", "用电安全");
    this.$store.commit("selectChildren", "特高频局放");
    this.chartParam.id=sessionStorage.getItem("stationId")
    this.requestParam.stationId=sessionStorage.getItem("stationId")
    this.getDate();
  },
  mounted() {
    this.queryPartialDischarge();
    this.partialDischargeChart();
    this.partialDischargeList();
  },
  methods: {
    // 获取当年
    getDate() {
      let date = new Date(); // 获取当前时间
      const year = date.getFullYear(); // 获取当前年份
      const currentMonth = date.getMonth() + 1; // 获取当前月份
      const currentDay = date.getDate(); //获取当前日
      let month = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // 获取当前月份
      let day = currentDay < 10 ? `0${currentDay}` : currentDay; //获取当前日
      this.chartParam.endTime = `${year}-${month}-${day} 23:59:59`;
      this.chartParam.staTime = `${year}-${month}-${day} 00:00:00`;
    },
    queryPartialDischarge() {
      securityControlApi.queryPartialDischarge(this.chartParam).then((res) => {
        if (res.code === 200) {
          let avgPeak = [];
          let sumPulse = [];
          let avgJun = [];
          // 有功功率
          const xAxisData = res.data.map((item) => {
            let month = item.tsInit.slice(11, 16);
            avgPeak.push(item.peak);
            sumPulse.push(item.sumPulse);
            avgJun.push(item.caiyangjunzhi);
            return month;
          });
          this.drawLineChart(xAxisData, sumPulse,avgPeak, avgJun);
        }
      });
    },
    //列表查询
    partialDischargeList() {
      this.loading = true;
      securityControlApi.partialDischargeList(this.requestParam).then((res) => {
        if (res.code == 200) {
          let item1={}
          let item2={}
          let item3={}
          let item4={}
          let item5={}
          let item6={}
          let item7={}
          let item8={}
          if(res.data.length!==0){
            res.data.forEach(item=>{
             item1={
              ultrasonicPeakName:'1号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak1,
              ultrasonicMeanName:'1号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean1,
              earthWavePeakName:'1号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak1,
              earthWaveMeanName:'1号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean1,
            }
            item2={
              ultrasonicPeakName:'2号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak2,
              ultrasonicMeanName:'2号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean2,
              earthWavePeakName:'2号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak2,
              earthWaveMeanName:'2号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean2,
            }
            item3={
              ultrasonicPeakName:'3号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak3,
              ultrasonicMeanName:'3号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean3,
              earthWavePeakName:'3号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak3,
              earthWaveMeanName:'3号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean3,
            }
            item4={
              ultrasonicPeakName:'4号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak4,
              ultrasonicMeanName:'4号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean4,
              earthWavePeakName:'4号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak4,
              earthWaveMeanName:'4号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean4,
            }
            item5={
              ultrasonicPeakName:'5号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak5,
              ultrasonicMeanName:'5号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean5,
              earthWavePeakName:'5号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak5,
              earthWaveMeanName:'5号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean5,
            }
            item6={
              ultrasonicPeakName:'6号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak6,
              ultrasonicMeanName:'6号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean6,
              earthWavePeakName:'6号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak6,
              earthWaveMeanName:'6号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean6,
            }
            item7={
              ultrasonicPeakName:'7号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak7,
              ultrasonicMeanName:'7号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean7,
              earthWavePeakName:'7号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak7,
              earthWaveMeanName:'7号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean7,
            }
            item8={
              ultrasonicPeakName:'8号设备超声波峰值',
              ultrasonicPeakValue:item.ultrasonicPeak8,
              ultrasonicMeanName:'8号设备超声波均值',
              ultrasonicMeanValue:item.ultrasonicMean8,
              earthWavePeakName:'8号设备地电波峰值',
              earthWavePeakValue:item.earthWavePeak8,
              earthWaveMeanName:'8号设备地电波均值',
              earthWaveMeanValue:item.earthWaveMean8,
            }
          });
          this.tableData.push(item1,item2,item3,item4,item5,item6,item7,item8);
          }
          this.loading = false;
          this.total = res.total;
        }
      });
    },
    partialDischargeChart() {
      securityControlApi.partialDischargeChart(this.chartParam).then((res) => {
        if (res.code === 200) {
          let xAxisData = [];
          let seriesData = [];
          if (res.data.length !== 0) {
            const object = res.data[0];
            for (const key in object) {
              // console.log(key);
              xAxisData.push(key);
              if (object[key]) {
                seriesData.push(object[key]);
              } else {
                seriesData.push(`0`);
              }
            }
          }
         var indices = xAxisData.map(function(image) {
              return  parseInt(image.match(/\d+/)[0]);
          });
          console.log(indices);

          this.drawHistogram(indices, seriesData);
        }
      });
    },
    drawLineChart(xAxisData, avgPeak, sumPulse,avgJun) {
      const lineChart = echarts.init(document.getElementById("dischargeChart"));
      var option = {
        grid: {
          left: "5%",
          right: "5%",
          containLabel: true,
        },
        tooltip: {},
        backgroundColor: "#071B41",
        legend: {
          data: ["放电次数", "放电峰值",'放电均值'],
          right: "31px",
          top: "16px",
          textStyle: {
            color: "#AED6FF",
          },
        },
        color: ["#2a65b4", " #2fc9cb",'#FFF04A'],
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: xAxisData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
        },
        yAxis: {
          type: "value",
          name: "用电量：C",
          nameTextStyle: {
            color: "#aed6ff",
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", //
            },
          },
        },
        dataZoom: [
          {
            type: "slider", // 数据缩放类型为滑动条
            start: 0, // 开始位置（范围为 0 到 100）
            end: 100, // 结束位置（范围为 0 到 100）
          },
          {
            type: "inside", // 内置的数据缩放类型，鼠标滚轮缩放
            start: 0, // 开始位置（范围为 0 到 100）
            end: 100, // 结束位置（范围为 0 到 100）
          },
        ],
        series: [
          {
            data: sumPulse,
            type: "line",
            name: "放电次数",
          },
          {
            data: avgPeak,
            type: "line",
            name: "放电峰值",
          },
          {
            data: avgJun,
            type: "line",
            name: "放电均值",
          },
        ],
      };

      lineChart.setOption(option);
    },
    drawHistogram(xAxisData, seriesData) {
      var histogram = echarts.init(document.getElementById("dBmChart"));

      var option = {
        grid: {
          left: "8%",
          right: "2%",
          containLabel: true,
        },
        tooltip: {},
        // legend: {
        //   data: ["总用电量"],
        //   right: '31px',
        //   top: '16px',
        //   textStyle: {
        //     color: "#AED6FF"
        //   }
        // },
        backgroundColor: "#071A40",
        xAxis: {
          data: xAxisData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置 x 轴标签字体颜色
            },
          },
          scale: true,
          axisLine: {
            onZero: false,
          },
        },
        yAxis: {
          name: " 采样图谱 : dBm",
          nameTextStyle: {
            color: "#aed6ff",
            fontSize: 14,
          },
          axisLabel: {
            color: "#aed6ff",
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              type: "dashed", //
              color: "#0b2159",
            },
          },
          // min: -80,
        },
        series: [
          {
            name: "dBm",
            type: "bar",

            data: seriesData,
            barWidth: "30%", // 调整柱状图的宽度
            itemStyle: {
              barBorderRadius: [0, 0, 0, 0], // 设置圆角半径，分别对应左上、右上、右下、左下四个角
              color: "#176AD2",
            },
          },
        ],
      };
      histogram.setOption({
        dataZoom: [
          {
            type: "slider", // 数据缩放类型为滑动条
            start: 0, // 开始位置（范围为 0 到 100）
            end: 100, // 结束位置（范围为 0 到 100）
          },
          {
            type: "inside", // 内置的数据缩放类型，鼠标滚轮缩放
            start: 0, // 开始位置（范围为 0 到 100）
            end: 100, // 结束位置（范围为 0 到 100）
          },
        ],
      });

      histogram.setOption(option);
    },

    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.partialDischargeList();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.partialDischargeList();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.partialDischargeList();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.partialDischargeList();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.partialDischargeList();
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
/* import */
.partial-discharge {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 26px;
  box-sizing: border-box;
}
.chart-content {
  margin-bottom: 16px;
}
.chart {
  display: flex;
  justify-content: space-between;
}
.chart div {
  width: 49%;
}
</style>
